import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import about1 from "../../assets/about1.png";
import about2 from "../../assets/about2.png";
import about2_wpp from "../../assets/about2_wpp.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <figure className="left">
            <img src={about1} alt="Foto de Casamento" />
          </figure>

          <div className="right">
            <h2 className="title">
              Aqui, REALIZAMOS Sonhos! E a receita é simples:
            </h2>

            <p className="text">
              Adicionamos nossa vasta experiência dos 8 anos de funcionamento à
              uma excelente estrutura predial
              <br />
              <br />
              Acrescentamos uma pitada DA MELHOOOOOR equipe de apoio. Melhor
              mesmo Nossa equipe, enquanto trabalha, DEIXA O CORAÇÃO EM FESTA
              <br />
              <br />E então completamos com o ingrediente principal: AMOR
              <br />
              <br />
              Colocamos energia e emoção em todas as etapas e nos desdobramos
              para fazer com que cada expectativa seja não apenas atendida, mas
              superada
              <br />
              <br />
              Não acredita? Leia os quase 300 depoimentos na nossa página ou
              venha nos fazer uma visita.
              <br />
              <br />
              Surpreenda-se!
              <br />E REALIZE-se também...
            </p>
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <h2 className="title">
              O que está esperando para fazer <b> o seu evento conosco?</b>
            </h2>

            <CtaWpp />

            <a
              href="http://wa.me/+5521998427156?text=Olá,%20Quero%20fazer%20a%20cotação%20da%20minha%20festa"
              target="_blank"
              rel="noreferrer"
              className="middle"
            >
              <figure>
                <img src={about2_wpp} alt="Ícone do Whatsapp" />
              </figure>

              <p className="text">(21) 99842-7156</p>
            </a>

            <div className="bottom">
              <div className="item">
                <figure>
                  <img src={about2_1} alt="Parcelado no cartão de crédito" />
                </figure>

                <p className="text">Parcelado no cartão de crédito</p>
              </div>

              <div className="item">
                <figure>
                  <img src={about2_2} alt="PIX" />
                </figure>

                <p className="text">PIX</p>
              </div>

              <div className="item">
                <figure>
                  <img src={about2_3} alt="Dinheiro" />
                </figure>

                <p className="text">Dinheiro</p>
              </div>
            </div>
          </div>

          <figure className="right">
            <img src={about2} alt="Foto de Casamento" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
