import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import logo_topo from "../../assets/logo_topo.png";
import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container vertical">
          <figure className="logo">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">
            FESTAS E EVENTOS CONFRATERNIZAÇÕES SERVIÇOS e LOCAÇÕES
          </h1>

          <p className="text">
            Oferecemos BUFFET COMPLETO com todos os serviços para qualquer tipo
            de evento. Fazemos a FESTA COMPLETA.
            <br />
            <br />
            Temos também a locação somente do espaço com mesas e cadeiras
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <div className="left">
            <h2 className="title">
              E ainda, oferecemos Locações e Serviços separados do Buffet para
              sua festa
            </h2>

            <CtaWpp />

            <figure>
              <img src={header2_1} alt="Foto 1 de Casamento" />
            </figure>
          </div>

          <div className="right">
            <figure>
              <img src={header2_2} alt="Foto 2 de Casamento" />
            </figure>

            <figure>
              <img src={header2_3} alt="Foto 3 de Casamento" />
            </figure>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
