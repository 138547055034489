import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <figure className="logo">
            <img src={logo_rodape} alt="Logo" />
          </figure>

          <p className="text">
            © 2024 - Realize - Todos os direitos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
