import { useState } from "react";
import "./styles.css";

const CtaWpp = () => {
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState("Fale agora no WhatsApp");

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+5521998427156?text=Olá,%20Quero%20fazer%20a%20cotação%20da%20minha%20festa"
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </a>
    </button>
  );
};

export default CtaWpp;
